











































































import Vue from "vue";
import { DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import moment from "moment";
import { mapState } from "vuex";
import MNotificationVue from "@/mixins/MNotification.vue";
import { OptionInvoiceQC, RequestInvoiceQCSubmit } from "@/models/interface-v2/invoice.interface";
import { invoiceServices } from "@/services-v2/invoice.service";
import { INVOICE_QC_OPTIONS } from "@constant/invoice.constant";
import { AR_INVOICE_STATUS } from "@/models/enums/invoice.enum";

const ALLOW_SAVE: AR_INVOICE_STATUS[] = [
  AR_INVOICE_STATUS.NEED_APPROVAL,
];

export default Vue.extend({
  name: "InvoiceModalPosting",
  mixins: [
    MNotificationVue,
  ],
  props: {
    value: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      loading: {
        approve: false,
        update: false,
      },
      dtSource: [] as OptionInvoiceQC[],
    };
  },
  computed: {
    ...mapState({
      detailInvoice: (st: any) => st.invoiceStore.detailInvoice,
      formQC: (st: any) => st.invoiceStore.formInvoiceQc,
    }),
    allowSave(): boolean {
      return ALLOW_SAVE.includes(this.detailInvoice.status);
    },
  },
  watch: {
    "value": {
      handler: "onFormQChange"
    }
  },
  created(): void {
    this.dtSource = INVOICE_QC_OPTIONS;
  },
  methods: {
    moment,
    onFormQChange(newVal): void {
      if (newVal && this.detailInvoice.invoiceQC && this.detailInvoice.invoiceQC.length) {
        this.dtSource.forEach((x, i) => {
          x.description = this.detailInvoice.invoiceQC[i].description;
          x.notes = this.detailInvoice.invoiceQC[i].notes;
        });
      } else {
        this.dtSource.forEach((x) => {
          x.description = "";
          x.notes = null;
        });
      }
    },
    handleClose(): void {
      this.$emit("input", false);
    },
    validateForm(): void {
      this.handleSave();
    },
    async handleSave(): Promise<void> {
      try {
        if (!this.detailInvoice.id) return;
        this.loading.update = true;
        const payload: RequestInvoiceQCSubmit[] = this.dtSource.map(x => ({
          description: x.description || "",
          notes: x.notes || ""
        }));
        await invoiceServices.submitQcInvoice(this.detailInvoice.id, payload);
        this.emitOnSave();
        this.showSuccessMessage("notif_update_success");
      } catch (error) {
        this.showErrorMessage("notif_update_fail");
      } finally {
        this.loading.update = false;
      }
    },
    emitOnSave(): void {
      this.$emit("on-save");
    },
  }
});
