import { OptionInvoiceQC } from "../interface-v2/invoice.interface";

export const INVOICE_QC_OPTIONS: OptionInvoiceQC[] = [
  {
    key: 0,
    description: "",
    notes: "",
    criteria: "lbl_qty_weight",
    label: ["lbl_sesuai", "lbl_tidak_sesuai"],
    value: ["Sesuai", "Tidak Sesuai"],
  },
  {
    key: 1,
    description: "",
    notes: "",
    criteria: "lbl_products_colour",
    label: ["lbl_baik", "lbl_tidak_baik"],
    value: ["Baik", "Tidak Baik"],
  },
  {
    key: 2,
    description: "",
    notes: "",
    criteria: "lbl_packaging_condition",
    label: ["lbl_baik", "lbl_rusak"],
    value: ["Baik", "Rusak"],
  },
  {
    key: 3,
    description: "",
    notes: "",
    criteria: "lbl_cutting_process",
    label: ["lbl_sesuai", "lbl_tidak_sesuai"],
    value: ["Sesuai", "Tidak Sesuai"],
  },
  {
    key: 4,
    description: "",
    notes: "",
    criteria: "lbl_product_condition",
    label: ["lbl_chilled_0_to_4", "lbl_frozen_than_minus_5"],
    value: ["Chilled", "Frozen"],
  },
];
