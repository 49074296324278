var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.$t("lbl_quality_control"),
        visible: _vm.value,
        "destroy-on-close": true,
        closable: false,
        width: "920px"
      }
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-table",
                {
                  attrs: {
                    "data-source": _vm.dtSource,
                    pagination: false,
                    scroll: { y: 320 }
                  }
                },
                [
                  _c(
                    "a-table-column",
                    {
                      key: "criteria",
                      attrs: { "data-index": "criteria", width: "200px" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text) {
                            return _c("span", {}, [
                              _vm._v(_vm._s(_vm.$t(text)))
                            ])
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_criteria")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "notes",
                      attrs: { "data-index": "notes" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record, index) {
                            return _c(
                              "a-radio-group",
                              {
                                attrs: { name: "radioGroup-notes-" + index },
                                model: {
                                  value: record.notes,
                                  callback: function($$v) {
                                    _vm.$set(record, "notes", $$v)
                                  },
                                  expression: "record.notes"
                                }
                              },
                              [
                                _c(
                                  "a-radio",
                                  { attrs: { value: record.value[0] } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t(record.label[0])) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "a-radio",
                                  { attrs: { value: record.value[1] } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t(record.label[1])) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_recipient_information")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "description",
                      attrs: { "data-index": "description" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return _c("a-textarea", {
                              attrs: {
                                "auto-size": { minRows: 1, maxRows: 4 },
                                placeholder: _vm.$t("lbl_type_here")
                              },
                              model: {
                                value: record.description,
                                callback: function($$v) {
                                  _vm.$set(record, "description", $$v)
                                },
                                expression: "record.description"
                              }
                            })
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_description")))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleClose } }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")
          ]),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.allowSave,
                  expression: "allowSave"
                }
              ],
              attrs: {
                type: "primary",
                loading: _vm.loading.update,
                icon: "save"
              },
              on: { click: _vm.validateForm }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }